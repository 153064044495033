import sbjs from 'sourcebuster'

export default defineNuxtPlugin(() => {
  sbjs.init()
  return {
    provide: {
      sbjs,
    },
  }
})
